import request from 'superagent';
import { API_URL } from '../config';
import { useState, useEffect } from 'react';

const useAllRestaurants = () => {
  const [filter, setFilter] = useState('');
  const [items, setItems] = useState([]);
  const [all, setAllItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [onlyEnabled, setOnlyEnabled] = useState(true);
  useEffect(() => {
    const fetchRestaurants = async () => {
      setLoading(true);
      setError(false);
      try {
        const res = await request.get(`${API_URL}/restaurants`);
        setAllItems(res.body.items);
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }
    fetchRestaurants()
  }, []);

  useEffect(() => {
    if (filter) {
      const filteredItems = all.filter(({ name }) => {
        const t = name.toLowerCase();
        return t.includes(filter);
      }).filter(r => {
        if (onlyEnabled) {
          return r.enabled === onlyEnabled;
        }
        return true;
      });
      setItems(filteredItems);
    } else {
      setItems(all.filter(r => {
        if (onlyEnabled) {
          return r.enabled === onlyEnabled;
        }
        return true;
      }));
    }
  }, [all, filter, onlyEnabled])

  return { loading, error, items, filter, setFilter, setOnlyEnabled, onlyEnabled, all };
}

export default useAllRestaurants;
