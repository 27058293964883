import request from 'superagent';
import { useEffect, useState } from 'react';
import { API_URL } from '../config';

const useRandomToTag = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [items, setItems] = useState([]);
  useEffect(() => {
    const fetchImagesToTag = async () => {
      try {
        const res = await request.get(`${API_URL}/images/no-tags`);
        setItems(res.body);
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }
    fetchImagesToTag();
  }, []);
  return { loading, error, items };
}

export default useRandomToTag;
