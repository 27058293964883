import React from 'react';
import Button from './Button';
import { Flex, Box, Text } from 'rebass';
import {
  Modal,
  ModalHeader,
  ModalFooter,
} from 'baseui/modal';
import useDelete from '../hooks/useDelete';

const DeleteButton = ({ id, type, history, ...rest }) => {
  const { loading, error, remove, isOpen, setIsOpen } = useDelete(type);
  const close = () => setIsOpen(false);
  return (
    <>
      <Modal onClose={close} isOpen={isOpen}>
        <ModalHeader>Are you suuuure?</ModalHeader>
        <ModalFooter>
          <Button mr={3} variant="primary" onClick={close}>Cancel</Button>
          <Button
            variant="danger"
            onClick={async () => {
              await remove(id);
              history.replace(`/${type}s`);
            }}
          >
            DELETE
          </Button>
        </ModalFooter>
      </Modal>
      <Flex flexDirection="column" alignItems="center">
        <Box mb={2}>
          <Button
            onClick={() => setIsOpen(true)}
            isLoading={loading}
            disabled={loading}
            variant="danger"
            {...rest}
          >
            Delete
          </Button>
        </Box>
        <Box>{error && <Text color="red">Error while deleting</Text>}</Box>
      </Flex>
    </>
  );
};

export default DeleteButton;
