import React from 'react';
import { Flex, Text, Box } from 'rebass';
import { Route } from 'react-router-dom';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import EditImage from '../components/EditImage';
import useAllFoodImages from '../hooks/useAllFoodImages';

function getColor(currentItem, selectedId) {
  if (selectedId === currentItem.id) {
    return '#fff';
  }
  if (currentItem.enabled) {
    return '#111';
  } else {
    return '#aaa';
  }
}

const AllFood = ({ history, match }) => {
  const { loading, error, items, filter, setFilter } = useAllFoodImages();
  const { foodImageId } = match.params;

  if (loading) return <Text p={3}>Loading...</Text>;
  if (error) return <Text p={3}>Error :(</Text>;
  return (
    <Flex height="100%" flex={1}>
      <Flex
        alignItems="flex-start"
        width={220}
        height="100%"
        style={{ overflow: 'hidden' }}
        bg="#fafcfc"
        flexDirection="column"
      >
        <Flex py={2} width={1} alignItems="center">
          <Box
            value={filter}
            onChange={e => setFilter(e.target.value)}
            placeholder="Filter..."
            as="input"
            width={1}
            height={42}
            variant="textInput"
          />
        </Flex>
        <Box width={1} height="100%">
          <AutoSizer>
            {({ height, width }) => (
              <FixedSizeList
                height={height}
                width={width}
                itemSize={46}
                itemCount={items.length}
              >
                {({ index, style }) => (
                  <Flex
                    key={items[index].id}
                    onClick={() => history.push(`/images/${items[index].id}`)}
                    width={1}
                    variant="listItem"
                    p={1}
                    style={style}
                    alignItems="center"
                    bg={foodImageId === items[index].id ? 'primary' : '#fff'}
                  >
                    <Text
                      fontWeight={
                        foodImageId !== items[index].id ? '400' : '600'
                      }
                      color={getColor(items[index], foodImageId)}
                      fontSize={1}
                    >
                      {items[index].title}
                    </Text>
                  </Flex>
                )}
              </FixedSizeList>
            )}
          </AutoSizer>
        </Box>
      </Flex>
      <Route path="/images/:foodImageId" component={EditImage} />
    </Flex>
  );
};

export default AllFood;
