import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import request from "superagent";
import { API_URL } from "../config";

function useTranslate(id, lang) {
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const translate = async () => {
    setLoading(true);
    setError(false);
    try {
      const token = await getAccessTokenSilently();
      const res = await request
        .get(`${API_URL}/images/${id}/translate/${lang}`)
        .set("Authorization", `Bearer ${token}`);
      return res.body;
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };
  return { loading, error, translate };
}

export default useTranslate;
