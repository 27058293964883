import request from 'superagent';
import { API_URL } from '../config';
import { useState, useEffect } from 'react';

const useAllFoodImages = () => {
  const [filter, setFilter] = useState('');
  const [items, setItems] = useState([]);
  const [all, setAllItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  useEffect(() => {
    const fetchImages = async () => {
      setLoading(true);
      setError(false);
      try {
        const res = await request.get(`${API_URL}/images`);
        setItems(res.body.items);
        setAllItems(res.body.items);
      } catch (err) {
        setError(true);
      } finally {
        setLoading(false);
      }
    }
    fetchImages()
  }, []);

  useEffect(() => {
    if (filter) {
      const filteredItms = all.filter(({ title }) => {
        const t = title.toLowerCase();
        return t.includes(filter);
      });
      setItems(filteredItms);
    } else {
      setItems(all);
    }
  }, [all, filter])

  return { loading, error, items, filter, setFilter };
}

export default useAllFoodImages;
