import React from 'react';
import Button from './Button';
import { Flex, Box, Text } from 'rebass';
import useSaveRestaurant from '../hooks/useSaveRestaurant';

const SaveRestaurantButton = ({ restaurant, dirty, setDirty }) => {
  const { loading, error, saveRestaurant } = useSaveRestaurant(
    restaurant.id,
  );
  return (
    <Flex flexDirection="column" alignItems="flex-end">
      <Box mb={2}>
        <Button
          onClick={async () => {
            await saveRestaurant(restaurant);
            setDirty(false);
          }}
          isLoading={loading}
          disabled={loading || !dirty}
          variant="primary"
        >
          Save
        </Button>
      </Box>
      <Box>{error && <Text color="red">Error while saving</Text>}</Box>
    </Flex>
  );
};

export default SaveRestaurantButton;
