import { useState, useEffect, useCallback } from 'react';
import request from 'superagent';
import { API_URL } from '../config';

const useEditRestaurant = (id) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [cuisineTypes, setCuisineTypes] = useState([]);
  const [restaurant, setRestaurant] = useState({
    id,
    name: '',
    logo: '',
    cuisineType: '',
    phone: '',
    instagram: '',
    facebook: '',
    website: '',
    address: '',
    city: 'lugano',
    location: {
      type: 'Point',
      coordinates: [0, 0],
    },
    placeID: '',
    enabled: false,
    bookable: true,
    tradingHours: JSON.stringify({}),
    menuUrl: '',
  });
  const [dirty, setDirty] = useState(false);
  const fetchRestaurant = useCallback(async () => {
    setLoading(true);
    try {
      const res = await request.get(`${API_URL}/restaurants/${id}`);
      const r = res.body;
      r.tradingHours = JSON.stringify(r.tradingHours);
      setRestaurant(r);
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [id]);
  const fetchCuisineTypes = async () => {
    try {
      const res = await request.get(`${API_URL}/cuisine-types`);
      setCuisineTypes(res.body);
    } catch (err) {
      // handle err
    }
  };
  useEffect(() => {
    fetchRestaurant();
    fetchCuisineTypes();
  }, [id, fetchRestaurant]);
  const updateRestaurant = (data) => {
    setDirty(true);
    setRestaurant(data);
  };
  const setField = (name, value) => {
    const updatedRestaurant = { ...restaurant };
    updatedRestaurant[name] = value;
    setRestaurant(updatedRestaurant);
    setDirty(true);
  };
  const setLocation = (type, value) => {
    const updatedRestaurant = { ...restaurant };
    const i = type === 'longitude' ? 0 : 1;
    updatedRestaurant.location.coordinates[i] = value;
    setRestaurant(updatedRestaurant);
    setDirty(true);
  };
  return {
    loading,
    error,
    restaurant,
    dirty,
    updateRestaurant,
    setField,
    setLocation,
    setDirty,
    cuisineTypes,
  };
};

export default useEditRestaurant;
