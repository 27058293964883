import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { NavLink } from "react-router-dom";
import { Box, Button, Flex, Text } from "rebass";
import HeaderProfile from "./HeaderProfile";

export default function NavHeader() {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();
  return (
    <Flex
      sx={{
        position: "sticky",
        borderBottom: "1px solid #ddd",
      }}
      px={3}
      height={50}
      flexDirection="row"
      backgroundColor="#f8f9fa"
      alignItems="center"
    >
      <Box mr={4}>
        <NavLink style={{ textDecoration: "none" }} to="/">
          <Text fontSize={[2, 3]} fontWeight="bold">
            Mangiami CMS
          </Text>
        </NavLink>
      </Box>
      <Flex flex={1}>
        <Box px={3}>
          <NavLink
            activeStyle={{ fontWeight: "600" }}
            style={{ textDecoration: "none" }}
            to="/restaurants"
          >
            Restaurants (A-Z)
          </NavLink>
        </Box>
        <Box px={3}>
          <NavLink
            activeStyle={{ fontWeight: "600" }}
            style={{ textDecoration: "none" }}
            to="/images"
          >
            FoodImages (A-Z)
          </NavLink>
        </Box>
        <Box px={3}>
          <NavLink
            activeStyle={{ fontWeight: "600" }}
            style={{ textDecoration: "none" }}
            to="/no-tags"
          >
            No Tags
          </NavLink>
        </Box>
        {/* <Box px={3}>
          <a
            style={{ textDecoration: "none" }}
            href="https://data.heroku.com/dataclips/ekegmxsraozasilcfssvydxsuwij"
          >
            No Translations
          </a>
        </Box> */}
      </Flex>
      <Flex flexDirection="row" alignItems="center" justifyContent="flex-end">
        {isAuthenticated ? (
          <>
            <HeaderProfile />
            <Button
              variant="outline"
              onClick={() => logout({ returnTo: window.location.origin })}
            >
              Logout
            </Button>
          </>
        ) : (
          <Button variant="primary" onClick={() => loginWithRedirect()}>
            Login
          </Button>
        )}
      </Flex>
    </Flex>
  );
}
