import React from 'react';
import { Flex, Text } from 'rebass';
import { Route } from 'react-router-dom';
import EditImage from '../components/EditImage';
import useRandomToTag from '../hooks/useRandomToTag';

const AllNoTags = ({ history, match }) => {
  const { loading, error, items } = useRandomToTag();
  const { foodImageId } = match.params;

  if (loading) return <Text p={3}>Loading...</Text>;
  if (error) return <Text p={3}>Error :(</Text>;
  return (
    <Flex height="100%" flex={1}>
      <Flex
        alignItems="flex-start"
        width={220}
        height="100%"
        style={{ overflow: 'hidden' }}
        bg="#fafcfc"
        flexDirection="column"
      >
        {items.map(image => (
          <Flex
            key={image.id}
            onClick={() => history.push(`/no-tags/${image.id}`)}
            width={1}
            variant="listItem"
            py={1}
            height={42}
            px={1}
            alignItems="center"
            bg={foodImageId === image.id ? 'primary' : '#fff'}
          >
            <Text
              fontWeight={foodImageId !== image.id ? '400' : '600'}
              color={foodImageId !== image.id ? '#111' : '#fff'}
              fontSize={1}
            >
              {image.title}
            </Text>
          </Flex>
        ))}
      </Flex>
      <Route path="/no-tags/:foodImageId" component={EditImage} />
    </Flex>
  );
};

export default AllNoTags;
