import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import request from "superagent";
import { API_URL } from "../config";

const useSaveRestaurant = (id) => {
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const saveRestaurant = async (data) => {
    setLoading(true);
    setError(false);
    try {
      const restaurant = { ...data };
      restaurant.tradingHours = JSON.parse(restaurant.tradingHours);
      const token = await getAccessTokenSilently();
      await request
        .put(`${API_URL}/restaurants/${id}`)
        .set("Authorization", `Bearer ${token}`)
        .send(restaurant);
    } catch (err) {
      // TODO toaster error
      setError(true);
      throw err;
    } finally {
      setLoading(false);
    }
  };
  return { loading, error, saveRestaurant };
};

export default useSaveRestaurant;
