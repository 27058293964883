import React from 'react';
import { Text } from 'rebass';
import Button from './Button';
import useTranslate from '../hooks/useTranslate';

function TranslateButton({ id, lang, onTranslate }) {
  const { loading, error, translate } = useTranslate(id, lang);
  return (
    <>
      <Button
        variant="secondary"
        isLoading={loading}
        onClick={() => translate().then(onTranslate)}
      >{`Translate to ${lang}`}</Button>
      {error && <Text>Error</Text>}
    </>
  );
}

export default TranslateButton;
