import React from 'react';
import { Select } from 'baseui/select';
import { FormControl } from 'baseui/form-control';

const EditKeywords = ({ keywords, onChangeKeywords }) => {
  const formattedKeywords = keywords.map(k => ({ id: k, name: k }));
  return (
    <FormControl label="Keywords">
      <Select
        size="compact"
        creatable
        multi
        options={[]}
        labelKey="name"
        valueKey="id"
        onChange={({ value }) => onChangeKeywords(value.map(v => v.id))}
        value={formattedKeywords}
      />
    </FormControl>
  );
};

export default EditKeywords;
