import React from 'react';
import { Flex, Text, Box } from 'rebass';
import { Route, Switch } from 'react-router-dom';
import { FixedSizeList } from 'react-window';
import AutoSizer from 'react-virtualized-auto-sizer';
import EditRestaurant from '../components/EditRestaurant';
import useAllRestaurants from '../hooks/useAllRestaurants';
import EditImage from '../components/EditImage';
import FoodImagesSidebar from '../components/FoodImagesSidebar';

const AllRestaurants = ({ history, match }) => {
  const { restaurantId } = match.params;
  const {
    loading,
    error,
    items,
    filter,
    setFilter,
    onlyEnabled,
    setOnlyEnabled,
  } = useAllRestaurants();

  if (loading) return <Text p={3}>Loading...</Text>;
  if (error) return <Text p={3}>Error :(</Text>;
  return (
    <Flex height="100%" flex={1}>
      <Flex
        alignItems="flex-start"
        width={180}
        height="100%"
        // style={{ overflow: 'hidden' }}
        style={{ borderRight: '1px solid #ddd' }}
        bg="#fafcfc"
        flexDirection="column"
      >
        <Flex px={2} alignItems="center" justifyContent="flex-start" width={1}>
          <Box
            onChange={e => setOnlyEnabled(e.target.checked)}
            checked={onlyEnabled}
            placeholder="Filter..."
            as="input"
            type="checkbox"
            height={24}
            mr={2}
            variant="textInput"
          />
          <Text width={1} fontSize={0}>
            Enabled
          </Text>
        </Flex>
        <Box
          value={filter}
          onChange={e => setFilter(e.target.value)}
          placeholder="Filter..."
          as="input"
          width={1}
          height={42}
          variant="textInput"
        />
        <Box width={1} height="100%">
          <AutoSizer>
            {({ height, width }) => (
              <FixedSizeList
                height={height}
                width={width}
                itemSize={46}
                itemCount={items.length}
              >
                {({ index, style }) => (
                  <Flex
                    key={items[index].id}
                    onClick={() =>
                      history.push(`/restaurants/${items[index].id}`)
                    }
                    width={1}
                    variant="listItem"
                    py={1}
                    px={2}
                    style={style}
                    alignItems="center"
                    bg={restaurantId === items[index].id ? 'primary' : '#fff'}
                  >
                    <Text
                      fontWeight={restaurantId !== items[index].id ? '400' : '600'}
                      color={restaurantId !== items[index].id ? '#111' : '#fff'}
                      fontSize={1}
                    >
                      {items[index].name}
                    </Text>
                  </Flex>
                )}
              </FixedSizeList>
            )}
          </AutoSizer>
        </Box>
      </Flex>
      <Route
        path={[
          '/restaurants/:restaurantId/images/:foodImageId',
          '/restaurants/:restaurantId',
        ]}
        component={FoodImagesSidebar}
      />
      <Switch>
        <Route
          exact
          path="/restaurants/:restaurantId/images/:foodImageId"
          component={EditImage}
        />
        <Route exact path="/restaurants/:restaurantId" component={EditRestaurant} />
      </Switch>
    </Flex>
  );
};

export default AllRestaurants;
