import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Box, Flex, Image } from "rebass";
import AddButton from "./components/AddButton";

function App({ history }) {
  const { isAuthenticated } = useAuth0();

  if (!isAuthenticated) {
    return (
      <Flex justifyContent="center" alignItems="center" flex={1}>
        <Image src={require("./logo.png")} />
      </Flex>
    );
  }
  return (
    <Flex flexDirection="column" p={4}>
      <Flex>
        <Box px={2}>
          <AddButton
            type="restaurant"
            title="Aggiungi Ristorante"
            history={history}
          />
        </Box>
        <Box px={2}>
          <AddButton type="image" title="Aggiungi Piatto" history={history} />
        </Box>
      </Flex>
    </Flex>
  );
}

export default App;
