import { useAuth0 } from "@auth0/auth0-react";
import React, { useEffect, useState } from "react";
import { Flex, Text } from "rebass";
import request from "superagent";
import { API_URL } from "../config";

function getColor(currentItem, selectedId) {
  if (selectedId === currentItem.id) {
    return "#fff";
  }
  if (currentItem.enabled) {
    return "#111";
  } else {
    return "#aaa";
  }
}

const FoodImagesSidebar = ({ match, history }) => {
  const { restaurantId, foodImageId } = match.params;
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [items, setItems] = useState([]);
  const { getAccessTokenSilently } = useAuth0();
  useEffect(() => {
    const fetchImages = async () => {
      try {
        const token = await getAccessTokenSilently();
        const res = await request
          .get(`${API_URL}/restaurants/${restaurantId}/images`)
          .set("Authorization", `Bearer ${token}`);
        setItems(res.body);
      } catch (err) {
        console.error(err);
        setError(true);
      } finally {
        setLoading(false);
      }
    };
    fetchImages();
  }, [restaurantId, getAccessTokenSilently]);
  if (loading) {
    return <Text>Loading...</Text>;
  }
  if (error) {
    return <Text>Error :(</Text>;
  }
  const images = items.sort(function (a, b) {
    if (a.FoodImageTranslations[0].title < b.FoodImageTranslations[0].title) {
      return -1;
    }
    if (a.FoodImageTranslations[0].title > b.FoodImageTranslations[0].title) {
      return 1;
    }
    return 0;
  });
  return (
    <Flex
      alignItems="flex-start"
      width={180}
      height="100%"
      style={{ borderRight: "1px solid #ddd" }}
      bg="#fafcfc"
      flexDirection="column"
    >
      {images.map((image) => (
        <Flex
          key={image.id}
          onClick={() =>
            history.push(`/restaurants/${restaurantId}/images/${image.id}`)
          }
          width={1}
          variant="listItem"
          py={1}
          px={2}
          alignItems="center"
          bg={foodImageId === image.id ? "primary" : "#fff"}
          height={46}
        >
          <Text
            fontWeight={foodImageId !== image.id ? "400" : "600"}
            color={getColor(image, foodImageId)}
            fontSize={1}
          >
            {image.FoodImageTranslations[0].title}
          </Text>
        </Flex>
      ))}
    </Flex>
  );
};

export default FoodImagesSidebar;
