import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import request from "superagent";
import { API_URL } from "../config";

const useAddFoodImage = (type) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const { getAccessTokenSilently } = useAuth0();
  const create = async () => {
    setLoading(true);
    try {
      const token = await getAccessTokenSilently();
      const res = await request
        .post(`${API_URL}/${type}s`)
        .set("Authorization", `Bearer ${token}`);
      const { id } = res.body;
      return id;
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
    }
  };
  return { loading, error, create };
};

export default useAddFoodImage;
