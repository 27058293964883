import { Checkbox } from "baseui/checkbox";
import { FormControl } from "baseui/form-control";
import { Input } from "baseui/input";
import { Select } from "baseui/select";
import { Textarea } from "baseui/textarea";
import React from "react";
import { Box, Flex, Link, Text } from "rebass";
import useEditRestaurant from "../hooks/useEditRestaurant";
import DeleteButton from "./DeleteButton";
import SaveRestaurantButton from "./SaveRestaurantButton";

function sortByAz(a, b) {
  // Use toUpperCase() to ignore character casing
  const nameA = a.it.toUpperCase();
  const nameB = b.it.toUpperCase();

  let comparison = 0;
  if (nameA > nameB) {
    comparison = 1;
  } else if (nameA < nameB) {
    comparison = -1;
  }
  return comparison;
}

const EditRestaurant = ({ match, history }) => {
  const { restaurantId } = match.params;
  const {
    loading,
    error,
    restaurant,
    dirty,
    setField,
    setLocation,
    setDirty,
    cuisineTypes,
  } = useEditRestaurant(restaurantId);
  if (loading || cuisineTypes.length === 0) {
    return <Text>Loading...</Text>;
  }
  if (error) {
    return <Text>Error :(</Text>;
  }
  return (
    <Flex
      flexWrap="wrap"
      alignItems="flex-start"
      justifyContent="flex-start"
      alignContent="flex-start"
      flex={1}
      style={{ overflowY: "scroll" }}
      p={3}
    >
      <Flex width={1} justifyContent="flex-end">
        <Box mr={3}>
          <DeleteButton id={restaurantId} history={history} type="restaurant" />
        </Box>
        <Box>
          <SaveRestaurantButton
            restaurant={restaurant}
            dirty={dirty}
            setDirty={setDirty}
          />
        </Box>
      </Flex>
      <Box width={160} px={2}>
        <FormControl label="City">
          <Select
            options={[
              {
                id: "lugano",
                name: "lugano",
              },
              {
                id: "mendrisiotto",
                name: "mendrisiotto",
              },
              { id: "bellinzonese", name: "bellinzonese" },
              { id: "blenio", name: "blenio" },
              { id: "leventina", name: "leventina" },
              { id: "locarnese", name: "locarnese" },
              { id: "riviera", name: "riviera" },
              { id: "vallemaggia", name: "vallemaggia" },
              { id: "sopraceneri", name: "sopraceneri" },
            ]}
            size="compact"
            labelKey="id"
            valueKey="id"
            value={{ id: restaurant.city, name: restaurant.city }}
            onChange={({ value }) => setField("city", value[0].id)}
          />
        </FormControl>
      </Box>
      <Box flex={1} px={2}>
        <FormControl label="Name">
          <Input
            value={restaurant.name}
            size="compact"
            onChange={(event) => setField("name", event.currentTarget.value)}
          />
        </FormControl>
      </Box>
      <Box px={2} width={240}>
        <FormControl label="Cuisine type">
          <Select
            maxDropdownHeight="600px"
            options={cuisineTypes.sort(sortByAz)}
            size="compact"
            labelKey="it"
            valueKey="id"
            value={{
              id: restaurant.CuisineTypeId,
              it: cuisineTypes[restaurant.CuisineTypeId],
            }}
            onChange={({ value }) => setField("CuisineTypeId", value[0].id)}
          />
        </FormControl>
      </Box>
      <Box px={2}>
        <FormControl label="Enabled">
          <Checkbox
            checked={restaurant.enabled}
            onChange={(event) =>
              setField("enabled", event.currentTarget.checked)
            }
          />
        </FormControl>
      </Box>
      <Box px={2}>
        <FormControl label="Bookable">
          <Checkbox
            checked={restaurant.bookable}
            onChange={(event) =>
              setField("bookable", event.currentTarget.checked)
            }
          />
        </FormControl>
      </Box>
      <Box px={2} width={1}>
        <FormControl label="Logo">
          <Input
            value={restaurant.logo}
            size="compact"
            onChange={(event) => setField("logo", event.currentTarget.value)}
          />
        </FormControl>
      </Box>
      <Box px={2} width={1}>
        <FormControl label="Menu url">
          <Input
            value={restaurant.menuUrl}
            size="compact"
            onChange={(event) => setField("menuUrl", event.currentTarget.value)}
          />
        </FormControl>
      </Box>
      <Box px={2} width={1}>
        <FormControl label="Menu url statico da usare per QR code">
          <Link
            target="_blank"
            href={`https://mangiami2.fly.dev/redirect-menu/${restaurant.id}`}
          >{`https://mangiami2.fly.dev/redirect-menu/${restaurant.id}`}</Link>
        </FormControl>
      </Box>
      <Box width={200} px={2}>
        <FormControl label="Phone">
          <Input
            value={restaurant.phone || ""}
            size="compact"
            onChange={(event) => setField("phone", event.currentTarget.value)}
          />
        </FormControl>
      </Box>
      <Box width={260} px={2}>
        <FormControl label="Instagram">
          <Input
            value={restaurant.instagram || ""}
            size="compact"
            onChange={(event) =>
              setField("instagram", event.currentTarget.value)
            }
          />
        </FormControl>
      </Box>
      <Box width={260} px={2}>
        <FormControl label="Facebook">
          <Input
            value={restaurant.facebook || ""}
            size="compact"
            onChange={(event) =>
              setField("facebook", event.currentTarget.value)
            }
          />
        </FormControl>
      </Box>
      <Box width={260} px={2}>
        <FormControl label="Website">
          <Input
            value={restaurant.website || ""}
            size="compact"
            onChange={(event) => setField("website", event.currentTarget.value)}
          />
        </FormControl>
      </Box>
      <Box width={350} px={2}>
        <FormControl label="Address">
          <Input
            value={restaurant.address}
            size="compact"
            onChange={(event) => setField("address", event.currentTarget.value)}
          />
        </FormControl>
      </Box>
      <Box width={300} px={2}>
        <FormControl label="PlaceID">
          <Input
            value={restaurant.placeID || ""}
            size="compact"
            onChange={(event) => setField("placeID", event.currentTarget.value)}
          />
        </FormControl>
      </Box>
      <Box width={120} px={2}>
        <FormControl label="Latitude">
          <Input
            value={restaurant.location.coordinates[1]}
            size="compact"
            onChange={(event) =>
              setLocation("latitude", event.currentTarget.value)
            }
          />
        </FormControl>
      </Box>
      <Box width={120} px={2}>
        <FormControl label="Longitude">
          <Input
            value={restaurant.location.coordinates[0]}
            size="compact"
            onChange={(event) =>
              setLocation("longitude", event.currentTarget.value)
            }
          />
        </FormControl>
      </Box>
      <Box width={600} px={2}>
        <FormControl label="Trading Hours JSON">
          <Textarea
            value={restaurant.tradingHours}
            size="compact"
            rows={9}
            onChange={(event) =>
              setField("tradingHours", event.currentTarget.value)
            }
          />
        </FormControl>
      </Box>
    </Flex>
  );
};

export default EditRestaurant;
