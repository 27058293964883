import { ApolloProvider } from "@apollo/react-hooks";
import { Auth0Provider } from "@auth0/auth0-react";
import ApolloClient from "apollo-boost";
import { ThemeProvider } from "emotion-theming";
import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import { Flex } from "rebass";
import { Client as Styletron } from "styletron-engine-atomic";
import { Provider as StyletronProvider } from "styletron-react";
import NavHeader from "./components/NavHeader";
import PrivateRoute from "./components/PrivateRoute";
import { API_URL } from "./config";
import AllFood from "./containers/AllFood";
import AllNoTags from "./containers/AllNoTags";
import AllRestaurants from "./containers/AllRestaurants";
import Home from "./Home";
import "./index.css";
import theme from "./theme";

const client = new ApolloClient({
  uri: `${API_URL}/graphql`,
});

// A function that routes the user to the right place
// after login
const onRedirectCallback = (appState) => {
  window.history.replaceState(
    {},
    document.title,
    appState && appState.targetUrl
      ? appState.targetUrl
      : window.location.pathname
  );
};

const engine = new Styletron();

const Root = () => (
  <Auth0Provider
    domain="mangiami2.eu.auth0.com"
    clientId="MAkDBGTyjepQn3tLoRdR4ameHLr0fvUz"
    audience="https://cms.mangiami.ch"
    redirectUri={window.location.origin}
    onRedirectCallback={onRedirectCallback}
  >
    <ApolloProvider client={client}>
      <StyletronProvider value={engine}>
        <ThemeProvider theme={theme}>
          <BrowserRouter>
            <NavHeader />
            <Flex flex={1} height="calc(100vh - 50px)" flexDirection="column">
              <Switch>
                <Route path="/" exact component={Home} />
                <PrivateRoute
                  path={["/restaurants/:restaurantId", "/restaurants"]}
                  component={AllRestaurants}
                />
                <PrivateRoute
                  path={["/images/:foodImageId", "/images"]}
                  component={AllFood}
                />
                <PrivateRoute
                  path={["/no-tags/:foodImageId", "/no-tags"]}
                  component={AllNoTags}
                />
              </Switch>
            </Flex>
          </BrowserRouter>
        </ThemeProvider>
      </StyletronProvider>
    </ApolloProvider>
  </Auth0Provider>
);

ReactDOM.render(<Root />, document.getElementById("root"));
