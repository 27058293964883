import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import request from "superagent";
import { API_URL } from "../config";

const useDelete = (type) => {
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const remove = async (id) => {
    setLoading(true);
    setError(false);
    try {
      const token = await getAccessTokenSilently();
      await request
        .delete(`${API_URL}/${type}s/${id}`)
        .set("Authorization", `Bearer ${token}`);
    } catch (err) {
      // TODO toaster error
      setError(true);
      throw err;
    } finally {
      setLoading(false);
    }
  };
  return { loading, error, remove, isOpen, setIsOpen };
};

export default useDelete;
