import React from 'react';
import PropTypes from 'prop-types';
import { Flex, Button as B, Text } from 'rebass';
import Spinner from 'react-spinner-material';
import { Colors } from '../theme';

const Button = ({ isLoading, children, disabled, variant, ...rest }) => (
  <B {...rest} disabled={disabled} variant={disabled ? 'disabled' : variant}>
    <Flex alignItems="center">
      {isLoading && (
        <Spinner spinnerColor={disabled ? Colors : '#fff'} size={18} spinnerWidth={2} />
      )}
      <Text ml={isLoading ? 2 : 0}>{children}</Text>
    </Flex>
  </B>
);

Button.propTypes = {
  // ...ButtonProps,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]).isRequired,
  isLoading: PropTypes.bool,
};

Button.defaultProps = {
  isLoading: false,
};

export default Button;
