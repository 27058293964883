import React from 'react';
import { Flex, Text, Image, Box } from 'rebass';
import { Input } from 'baseui/input';
import { Select } from 'baseui/select';
import { FormControl } from 'baseui/form-control';
import { Checkbox } from 'baseui/checkbox';
import { Textarea } from 'baseui/textarea';
import EditTags from './EditTags';
import EditKeywords from './EditKeywords';
import useEditImage from '../hooks/useEditImage';
import SaveImageButton from './SaveImageButton';
import useAllRestaurants from '../hooks/useAllRestaurants';
import DeleteButton from './DeleteButton';
import TranslateButton from './TranslateButton';

const LANGUAGES = ['it', 'en', 'fr', 'de'];

const EditImage = ({ match, history }) => {
  const { foodImageId } = match.params;
  const {
    loading,
    error,
    foodImage,
    setField,
    dirty,
    setTranslatedField,
    setRestaurant,
    setDirty,
  } = useEditImage(foodImageId);
  const { loading: loadingRestaurants, all: restaurants } = useAllRestaurants();

  if (loading || loadingRestaurants) return <Text p={3}>Loading...</Text>;
  if (error) return <Text p={3}>Error :(</Text>;
  const translations = {};
  LANGUAGES.forEach(l => {
    const t = foodImage.FoodImageTranslations.find(({ lang }) => lang === l);
    if (t) {
      translations[l] = {
        title: t.title,
        description: t.description || '',
        keywords: t.keywords || [],
        FoodImageId: foodImage.id,
      };
    } else {
      translations[l] = {
        title: '',
        description: '',
        keywords: [],
        FoodImageId: foodImage.id,
      };
    }
  });
  return (
    <Flex flexDirection="column" flex={1} style={{ overflowY: 'scroll' }} p={3}>
      <Flex justifyContent="space-between" alignItems="center">
        <Flex alignItems="center" mb={2}>
          <Image
            mr={3}
            src={foodImage.Restaurant && foodImage.Restaurant.logo}
            width={80}
            height={80}
            variant="logo"
          />
          <Box>
            <Text mb={1} fontSize={4}>
              {foodImage.Restaurant && foodImage.Restaurant.name}
            </Text>
            <Text fontSize={2} variant="muted">
              {foodImage.Restaurant && foodImage.Restaurant.cuisineType}
            </Text>
          </Box>
        </Flex>
        <Box mx="auto" />
        <Flex px={2} alignItems="center">
          <Box width={300} mr={3}>
            <Select
              size="compact"
              options={restaurants}
              labelKey="name"
              valueKey="id"
              maxDropdownHeight="300px"
              type="search"
              value={foodImage.Restaurant}
              onChange={({ value }) => setRestaurant(value[0])}
              clearable
            />
          </Box>
          <DeleteButton
            id={foodImage.id}
            type="image"
            history={history}
            mr={3}
          />
          <SaveImageButton
            foodImage={foodImage}
            dirty={dirty}
            setDirty={setDirty}
          />
        </Flex>
      </Flex>
      <Flex css={{ minHeight: 300 }} mb={3} alignItems="flex-start">
        <Image
          alignSelf="center"
          mr={3}
          src={foodImage.imageUrl}
          width={250}
          height={250}
        />
        <Flex flex={1} mx={-2} width={1} flexWrap="wrap" flexDirection="row">
          <Box px={2} width={1}>
            <FormControl label="Image url">
              <Input
                value={foodImage.imageUrl}
                size="compact"
                onChange={event =>
                  setField('imageUrl', event.currentTarget.value)
                }
              />
            </FormControl>
          </Box>
          <Box px={2} width={1 / 2}>
            <EditTags
              onChangeTags={tags => setField('Tags', tags)}
              selectedTags={foodImage.Tags}
            />
          </Box>
          <Box px={2} width={100}>
            <FormControl label="Price">
              <Input
                value={foodImage.price}
                size="compact"
                onChange={event => setField('price', event.currentTarget.value)}
              />
            </FormControl>
          </Box>
          <Box width={160} px={2}>
            <FormControl label="Type">
              <Select
                size="compact"
                options={[
                  { id: 'star', name: 'star' },
                  { id: 'new', name: 'new' },
                  { id: 'food', name: 'food' },
                  { id: 'drink', name: 'drink' },
                  { id: 'restaurant', name: 'restaurant' },
                ]}
                searchable={false}
                labelKey="id"
                valueKey="id"
                value={{ id: foodImage.type, name: foodImage.type }}
                onChange={({ value }) => setField('type', value[0].id)}
              />
            </FormControl>
          </Box>
          <Box px={2}>
            <FormControl label="Copyright">
              <Checkbox
                checked={foodImage.copyright}
                onChange={event =>
                  setField('copyright', event.currentTarget.checked)
                }
              />
            </FormControl>
          </Box>
          <Box px={2}>
            <FormControl label="Dish of the day">
              <Checkbox
                checked={foodImage.dishOfTheDay}
                onChange={event =>
                  setField('dishOfTheDay', event.currentTarget.checked)
                }
              />
            </FormControl>
          </Box>
          <Box px={2}>
            <FormControl label="Searchable">
              <Checkbox
                checked={foodImage.searchable}
                onChange={event =>
                  setField('searchable', event.currentTarget.checked)
                }
              />
            </FormControl>
          </Box>
          <Box px={2} width={220}>
            <FormControl label="Photo by">
              <Input
                value={foodImage.photoBy || ''}
                size="compact"
                onChange={event =>
                  setField('photoBy', event.currentTarget.value)
                }
              />
            </FormControl>
          </Box>
          <Box px={2}>
            <FormControl label="Enabled">
              <Checkbox
                checked={foodImage.enabled}
                onChange={event =>
                  setField('enabled', event.currentTarget.checked)
                }
              />
            </FormControl>
          </Box>
        </Flex>
      </Flex>
      <Flex mx={-2}>
        {LANGUAGES.map(lang => (
          <Box key={lang} p={2} width={1 / 4}>
            <Text fontWeight="500">{lang.toUpperCase()}</Text>
            <Flex justifyContent="flex-start" flex={1} flexDirection="column">
              <FormControl label="Title">
                <Textarea
                  value={translations[lang].title}
                  size="compact"
                  rows={2}
                  onChange={event =>
                    setTranslatedField(lang, 'title', event.currentTarget.value)
                  }
                />
              </FormControl>
              <FormControl label="Description">
                <Textarea
                  value={translations[lang].description}
                  size="compact"
                  rows={5}
                  onChange={event =>
                    setTranslatedField(
                      lang,
                      'description',
                      event.currentTarget.value,
                    )
                  }
                />
              </FormControl>
              <EditKeywords
                onChangeKeywords={k => setTranslatedField(lang, 'keywords', k)}
                keywords={translations[lang].keywords}
              />
            </Flex>
            {lang !== 'it' && (
              <TranslateButton
                id={foodImageId}
                lang={lang}
                onTranslate={({ title, description, keywords }) => {
                  setTranslatedField(lang, 'title', title);
                  setTranslatedField(lang, 'description', description);
                  setTranslatedField(lang, 'keywords', keywords);
                }}
              />
            )}
          </Box>
        ))}
      </Flex>
    </Flex>
  );
};

export default EditImage;
