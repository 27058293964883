import React from "react";
import { Text } from "rebass";
import useCreate from "../hooks/useCreate";
import Button from "./Button";

const AddButton = ({ title, type, history }) => {
  const { loading, error, create } = useCreate(type);
  return (
    <>
      <Button
        onClick={async () => {
          console.log("ADD");
          try {
            const id = await create();
            history.push(`/${type}s/${id}`);
          } catch (err) {
            window.alert(err);
          }
        }}
        variant="primary"
        isLoading={loading}
      >
        {title}
      </Button>
      {error && <Text color="red">Error creating new {type}</Text>}
    </>
  );
};

export default AddButton;
