import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Flex, Image, Text } from "rebass";

const HeaderProfile = () => {
  const { loading, user } = useAuth0();
  if (loading || !user) {
    return null;
  }
  return (
    <Flex mr={2} alignItems="center">
      <Text fontSize={1} fontWeight="body" mr={2}>
        {user.email}
      </Text>
      <Image
        src={user.picture}
        width={32}
        sx={{
          borderRadius: 32,
        }}
      />
    </Flex>
  );
};

export default HeaderProfile;
