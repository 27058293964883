import { useAuth0 } from "@auth0/auth0-react";
import { useState } from "react";
import request from "superagent";
import { API_URL } from "../config";

const useSaveImage = (id) => {
  const { getAccessTokenSilently } = useAuth0();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const saveFoodImage = async (data) => {
    setLoading(true);
    setError(false);
    try {
      const token = await getAccessTokenSilently();
      await request
        .put(`${API_URL}/images/${id}`)
        .set("Authorization", `Bearer ${token}`)
        .send(data);
    } catch (err) {
      // TODO toaster error
      setError(true);
      throw err;
    } finally {
      setLoading(false);
    }
  };
  return { loading, error, saveFoodImage };
};

export default useSaveImage;
