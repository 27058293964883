import React from 'react';
import Button from './Button';
import { Flex, Box, Text } from 'rebass';
import useSaveImage from '../hooks/useSaveImage';

const SaveImageButton = ({ foodImage, dirty, setDirty }) => {
  const { loading, error, saveFoodImage } = useSaveImage(foodImage.id);
  return (
    <Flex flexDirection="column" alignItems="center">
      <Box mb={2}>
        <Button
          onClick={async () => {
            await saveFoodImage(foodImage);
            setDirty(false);
          }}
          isLoading={loading}
          disabled={loading || !dirty}
          variant="secondary"
        >
          Save
        </Button>
      </Box>
      <Box>
        {error && <Text color="red">Error while saving</Text>}
      </Box>
    </Flex>
  );
};

export default SaveImageButton;