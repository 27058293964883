import defaultTheme from '@rebass/preset';

export const Colors = {
  PRIMARY: '#ED3B4F',
  RED: '#ED3B4F',
  BLACK: '#111',
  GREY: '#909090',
  LIGHT_GREY: '#FAFAFA',
  MEDIUM_GREY: '#F5F5F5',
  GREEN: 'green',
  ORANGE: '#F7893E',
};

export default {
  ...defaultTheme,
  colors: {
    ...defaultTheme.colors,
    primary: Colors.RED,
    black: Colors.BLACK,
    grey: Colors.GREY,
  },
  variants: {
    listItem: {
      borderBottom: '1px solid #ddd',
      cursor: 'pointer',
      overflowWrap: 'break-word',
      wordWrap: 'break-word',
      hyphens: 'auto',
    },
    logo: {
      border: '1px solid #ddd',
      borderRadius: 9999,
    },
    textInput: {
      height: 42,
      fontSize: 2,
      padding: 2,
      border: '1px solid #ddd'
    }
  },
  text: {
    muted: {
      color: 'grey'
    }
  },
  buttons: {
    ...defaultTheme.buttons,
    disabled: {
      ...defaultTheme.buttons.primary,
      color: 'black',
      backgroundColor: '#F5F5F5',
      cursor: 'default',
      textTransform: 'uppercase',
      fontWeight: '500',
      boxShadow: '0 4px 6px rgba(50,50,93,.11), 0 2px 6px rgba(0,0,0,.08)',
    },
    primary: {
      ...defaultTheme.buttons.primary,
      cursor: 'pointer',
      textTransform: 'uppercase',
      fontWeight: '500',
      boxShadow: '0 4px 6px rgba(50,50,93,.11), 0 2px 6px rgba(0,0,0,.08)',
      bg: '#276ef1'
    },
    danger: {
      ...defaultTheme.buttons.primary,
      cursor: 'pointer',
      textTransform: 'uppercase',
      fontWeight: '500',
      boxShadow: '0 4px 6px rgba(50,50,93,.11), 0 2px 6px rgba(0,0,0,.08)',
    },
    nav: {
      backgroundColor: 'primary',
      color: '#000',
    },
    tabActive: {
      backgroundColor: '#E5E5E5',
      cursor: 'pointer',
      color: 'black'
    },
    tabInactive: {
      backgroundColor: 'transparent',
      cursor: 'pointer',
      color: 'black'
    }
  },
};
