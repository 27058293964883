import React from 'react';
import { Select } from 'baseui/select';
import { FormControl } from 'baseui/form-control';

function sortByAz(a, b) {
  // Use toUpperCase() to ignore character casing
  const nameA = a.name.toUpperCase();
  const nameB = b.name.toUpperCase();

  let comparison = 0;
  if (nameA > nameB) {
    comparison = 1;
  } else if (nameA < nameB) {
    comparison = -1;
  }
  return comparison;
}

const EditTags = ({ selectedTags, onChangeTags }) => {
  return (
    <FormControl label="Tags">
      <Select
        size="compact"
        options={[
          {
            id: 'bbdca54d-1ad5-4086-8bdb-4f6c5583fb31',
            name: 'Vegetariana',
          },
          {
            id: '2a314884-7b61-4b73-9047-04b58e652630',
            name: 'Vegana',
          },
          {
            id: '572c6fbf-e312-4852-949f-864768273ac5',
            name: 'Drinks',
          },
          {
            id: 'e7176419-b85e-49bf-bd05-af5f65b60463',
            name: 'Internazionale',
          },
          {
            id: 'f9c79d88-9e1a-447f-b6c1-ce2b2334d1c3',
            name: 'Colazione',
          },
          {
            id: 'f9a40969-4a8f-402f-ada6-97cc380768d4',
            name: 'Takeaway',
          },
          {
            id: 'f0247341-e76a-4678-936b-c73775be215d',
            name: 'Gelateria',
          },
          {
            id: 'ed93b2fd-1a53-4c44-917b-c1389e6d7575',
            name: 'Burger',
          },
          {
            id: 'd9b2ae99-6ddc-473f-b455-304e611d42f1',
            name: 'Grill',
          },
          {
            id: 'c68a12f7-fb65-42a5-9b61-c9ab6c659551',
            name: 'Ticinese',
          },
          {
            id: 'bfe74355-939d-4582-ab9c-72768719a13c',
            name: 'Tartare',
          },
          {
            id: 'a4002d95-4447-463b-b463-d6590633f6f3',
            name: 'Pizza',
          },
          {
            id: 'a15eca7b-4efd-42fe-9af5-f69c3128dc6e',
            name: 'Panino / Piadina',
          },
          {
            id: '9d87ebbb-5282-483c-8afc-560252985162',
            name: 'Dessert',
          },
          {
            id: '8eed4a6d-3c48-48ae-822b-e394a9d54d05',
            name: 'Carne',
          },
          {
            id: '85a37d8a-970f-4d91-8023-8adddf0f66c5',
            name: 'Pasta',
          },
          {
            id: '835e2f5e-886c-45dc-be62-ec2eafcc0fbc',
            name: 'No Tag',
          },
          {
            id: '6b3c469b-5b79-431d-a122-c58575758c93',
            name: 'Pesce',
          },
          {
            id: '56de5284-e56c-4ada-af9a-cef8e5b60b0e',
            name: 'Insalata',
          },
          {
            id: '5352e55c-956b-4093-8ffe-7f051841c1d5',
            name: 'Asiatica',
          },
          {
            id: '2a26f11d-c478-4681-9693-439563d364a3',
            name: 'Risotto',
          },
          {
            id: '0124242f-fd7b-449f-848f-7bdfadfa594e',
            name: 'Delivery',
          },
          {
            id: '29700e55-0717-4c3e-a357-3678954ada55',
            name: 'Estivo',
          },
          {
            id: '95f366e6-6919-4844-9c76-6b64290d11e4',
            name: 'Selvaggina',
          },
        ].sort(sortByAz)}
        labelKey="name"
        valueKey="id"
        placeholder="Select a tag"
        maxDropdownHeight="300px"
        multi
        onChange={({ value }) => onChangeTags(value)}
        value={selectedTags}
      />
    </FormControl>
  );
};

export default EditTags;
