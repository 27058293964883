import { useState, useEffect, useCallback } from 'react';
import request from 'superagent';
import { API_URL } from '../config';

const useEditImage = (id) => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [foodImage, setFoodImage] = useState({
    id,
    imageUrl: '',
    price: 0,
    RestaurantId: '',
    type: 'food',
    copyright: false,
    searchable: true,
    photoBy: '',
    dishOfTheDay: false,
    FoodImageTranslations: [],
    Restaurant: {
      name: '',
      logo: ''
    },
    enabled: false,
  });
  const [dirty, setDirty] = useState(false);
  const fetchImage = useCallback(async () => {
    setLoading(true);
    try {
      const res = await request
        .get(`${API_URL}/images/${id}`);
      setFoodImage(res.body);
    } catch (err) {
      setError(true);
    } finally {
      setLoading(false);
    }
  }, [id]);
  useEffect(() => {
    fetchImage();
  }, [id, fetchImage]);
  const updateFoodImage = (data) => {
    setDirty(true);
    setFoodImage(data);
  }
  const setField = (name, value) => {
    const updatedFoodImage = {...foodImage};
    updatedFoodImage[name] = value;
    setFoodImage(updatedFoodImage);
    setDirty(true);
  }
  const setRestaurant = (value) => {
    const updatedFoodImage = {...foodImage};
    updatedFoodImage.Restaurant = value;
    updatedFoodImage.RestaurantId = value.id;
    setFoodImage(updatedFoodImage);
    setDirty(true);
  }
  const setTranslatedField = (lang, name, value) => {
    const updatedFoodImage = {...foodImage};
    const translation = updatedFoodImage.FoodImageTranslations.find(t => t.lang === lang);
    if (translation) {
      translation[name] = value;
    } else {
      updatedFoodImage.FoodImageTranslations.push({
        lang,
        [name]: value,
        FoodImageId: foodImage.id,
      });
    }
    setFoodImage(updatedFoodImage);
    setDirty(true);
  }
  return { loading, error, setDirty, foodImage, dirty, updateFoodImage, setField, setRestaurant, setTranslatedField };
};

export default useEditImage;
